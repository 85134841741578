<template>
  <div>
    <v-card v-if="listOfComments.length > 0" flat class="pa-2">
      <v-row no-gutters>
        <v-col cols="12" sm="6" md="4" lg="4" xl="2" v-for="(comment, index) in listOfComments" :key="index">
          <div>
            <v-card class="mb-1 pt-1">
              <v-row class="mx-0 pa-1">
                <v-flex xs2 sm2>
                  <v-avatar color="primary" class="pa-0 mt-4 ml-2" size="25">
                    <span class="mx-auto  white--text">
                      <b>{{ comment.user_lastname ? `${comment.user_firstname.charAt(0).toUpperCase()}${comment.user_lastname.charAt(0).toUpperCase()}`  : comment.user_firstname.charAt(0).toUpperCase() }}</b>
                    </span>
                  </v-avatar>
                </v-flex>
                <v-flex xs8 sm8 class="mt-1 caption">
                  <span class="font-weight-medium"> {{comment.user_firstname}} {{comment.user_lastname}} </span>
                  <p class="font-weight-regular"> {{ $formatter.formatDate(comment.modified_at, 'DD.MM.YYYYTHH.mm.ss', `${userDetails.dateformat} HH:mm`) }} </p>
                </v-flex>
                <v-flex xs2 sm2 v-if="!comment.showEdit">
                  <v-layout row wrap class="mx-0 mt-4 mr-1" justify-end>
                    <v-icon @click="showEditHandler(index)" v-if="userId === comment.updated_by" :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" size="22">mdi-pencil-circle-outline</v-icon>
                    <v-icon color="error" @click="deleteComment(comment, index)" v-if="userId === comment.updated_by" size="22">mdi-delete-circle-outline</v-icon>
                  </v-layout>
                </v-flex>
              </v-row>
              <v-divider></v-divider>
              <v-row class="ma-0">
                <v-col cols="12" lg="10" class="px-1 pl-2 caption" v-if="!comment.showEdit">
                  <p v-html="comment.comment"></p>
                </v-col>
                <v-col cols="12" lg="11" v-else>
                  <div id="app-editor-comments">
                    <ckeditor :editor="editor" v-model="comment.comment" :config="configuration" id="app-comments-section" :key="reRender"></ckeditor>
                    <!-- <app-editor :editor="editor" :config="configuration" v-model="comment.comment" id="app-comments-section"></app-editor> -->
                  </div>
                  <v-flex class="text-right">
                    <v-btn-toggle v-model="toggle_exclusive">
                      <v-btn small @click="updateComments(index)">
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                      <v-btn small @click="listOfComments[index].showEdit = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-btn-toggle>
                  </v-flex>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-alert prominent text v-else icon="mdi-information" :color="!$vuetify.theme.dark && systemDetails && systemDetails.themecolor ? systemDetails.themecolor : ''" class="ma-3" >
      <v-row align="center">
        <v-col class="grow">{{ $t('noComments') }}</v-col>
      </v-row>
    </v-alert>
    <v-speed-dial bottom right fixed class="mb-12">
      <template v-slot:activator>
        <v-btn small :color="systemDetails && systemDetails.themecolor ? systemDetails.themecolor : 'primary'" :style="{ color: systemDetails.textcolor ? systemDetails.textcolor : 'white' }"
          fab dark @click="showCommentBox = true">
          <v-icon>mdi-comment-plus</v-icon>
        </v-btn>
      </template>
    </v-speed-dial>
    <v-dialog v-model="showDialog" persistent width="400" class="mt-0">
      <v-card>
        <v-card-title :style="`background-color: #ff5252; color: white`" class="pa-2 body-1 px-4 pt-3 pb-3">
          {{ $t('deleteConfirm') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <h4> {{ $t('areYouSure') }} </h4>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-flex class="text-center">
            <v-btn small color="error" id="deleteConfirm" class="mr-3" @click="confirmDeleteComment()">{{ $t('yes') }}</v-btn>
            <v-btn small outlined color="grey darken-2" id="deleteClose" @click="showDialog=false">{{ $t('no') }}</v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showCommentBox" persistent width="800" class="mt-0">
      <v-card>
        <v-card-title :style="$vuetify.theme.dark ? '' : `background-color: ${systemDetails.themecolor}; color: ${systemDetails.textcolor}`">
          {{ modelObj._id ? $t('update') : $t('add') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <div v-if="showCommentBox" class="commentBox  font-weight-regular text--primary">
            <ckeditor :editor="editor" v-model="modelObj.comment" :config="configuration"></ckeditor>
            <v-flex class="text-right">
              <v-btn-toggle v-model="toggle_exclusive" class="justify-right">
                <v-btn small @click="saveComments()" color="success">
                  <v-icon>mdi-check</v-icon>
                </v-btn>
                <v-btn small @click="showCommentBox = false, modelObj.comment = ''" color="error">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-flex>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-flex class="text-center">
            <v-btn small color="error" id="deleteClose" @click="showCommentBox=false">{{ $t('close') }}</v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import Editor from '@/ckeditor'
export default {
  props: ['ticketNumber', 'assignedto', 'ticketObj'],
  data () {
    return {
      ticketId: this.$route.params.ticket_id,
      editor: Editor,
      toggle_exclusive: 2,
      showCommentBox: false,
      userId: this.$store.state.auth.userDetails._id,
      modelObj: {
        ticket_id: this.$route.params.ticket_id,
        comment: ''
      },
      configuration: {
        height: '250px',
        sharedSpaces: { 
          top: 'toolbarLocation' 
        },
        toolbar: {
          items: [ 'bold', 'italic', 'outdent', 'indent', '|', 'bulletedList', 'numberedList', '|', 'insertTable', 'blockQuote', 'undo', 'redo']
        },
        mention: {},
      },
      listOfUsers: [],
      reRender: 0,
      selectedItem: {},
      showDialog: false,
      listOfComments: []
    }
  },
  computed: {
    ...mapGetters(['getUsers', 'getsignalRConnection', 'getListOfGroups', 'userDetails', 'systemDetails'])
  },
  mounted () {
    this.getAllComments()
    let users = this.$formatter.cloneVariable(this.getUsers)
    users.forEach(element => {
      let  id = element._id
      element.userId = id
      element.id = `@${element.name}`
      element.link = 'https://ckeditor.com/docs/ckeditor5/latest/examples/framework/chat-with-mentions.html'
    })
    this.listOfUsers = users
    this.configuration.mention = {
      feeds: [{
        marker: '@',
        feed: this.listOfUsers,
        itemRenderer: this.customItemRenderer
      }]
    }
  },
  methods: {
    getAllComments () {
      const query = [{ $match: { $and: [{ Ticket_Id: `${this.ticketObj._id}` }] } },
        {
          $lookup: {
            let: { userId: '$Updated_By' },
            from: 'UserSetting',
            pipeline: [
              { $match: { $expr: { $eq: ['$_id', { $convert: { input: '$$userId', to: 'objectId', onError: { error: true }, onNull: { isnull: true } } }] } } }, { $project: { _id: 1, FirstName: 1, LastName: 1 } }
            ],
            as: 'user_info'
          }
        }
      ]
      this.$api.execute('post', '/ticketcomments/query', query).then(response => {
        this.listOfComments = response.data
        this.listOfComments.forEach(element => {
          // element.modified_at = this.$formatter.fromUtcToLocal(element.modified_at, 'DD.MM.YYYYTHH:mm:ss')
          if (element.updated_by === -100) {
            element.user_firstname = 'Super'
            element.user_lastname = 'Admin'
          } else if (element.user_info.length > 0) {
            element.user_firstname = element.user_info[0].firstname
            element.user_lastname = element.user_info[0].lastname
          }
          if (!element.user_firstname) element.user_firstname = 'Unknown'
          element.showEdit = false
        })
      })
    },
    customItemRenderer( item ) {
      const itemElement = document.createElement( 'span' )
      itemElement.classList.add( 'custom-item' )
      itemElement.id = `mention-list-item-id-${ item.userId }`
      itemElement.textContent = `${ item.name } `
      const usernameElement = document.createElement( 'span' )
      // usernameElement.classList.add( 'custom-item-username' )
      // usernameElement.textContent = item.id
      // itemElement.appendChild( usernameElement )
      return itemElement
    },
    showEditHandler(index) {
      this.listOfComments[index].showEdit = true
      this.$set(this.listOfComments, index, this.listOfComments[index])
    },
    saveComments () {
      let mentionedIds = []
      let elements = document.querySelectorAll('.commentBox .mention')
      elements.forEach(element => {
        let id = element.attributes[2].value
        mentionedIds.push(parseInt(id))
      })
      let model = this.$formatter.cloneVariable(this.modelObj)
      this.$api.execute('post', 'ticketcomments', model).then(response => {
        if (mentionedIds.length > 0) {
          let  msg = `You are mentioned in ${this.ticketNumber} by ${this.$store.state.auth.userDetails.name}`
          this.getsignalRConnection.invoke('SendNotification', mentionedIds, msg, this.ticketId)
        }
        if (this.assignedto) {
          let assignedTo = this.$formatter.cloneVariable(this.assignedto)
          assignedTo = assignedTo.split('_')
          if (assignedTo[0] === 'group') {
            let item = this.getListOfGroups.find(x => x.id == assignedTo[1])
            let usersarray = []
            // let query = { filter: `groupid eq ${assignedTo[1]} and isactive eq 1` }
            // let  msg = `${this.$store.state.auth.userDetails.name} added comments in ${this.ticketNumber}`
            //   this.$api.execute('get', `usergroups/get_by_group/${assignedTo[1]}`).then(result => {
            //   usersarray = result.data.map(x => x.user_id)
            // }).finally(() => {
            //   this.getsignalRConnection.invoke('SendNotification', usersarray, msg, this.ticketId)
            // })
          } else {
            if (this.$store.state.auth.userDetails._id != assignedTo[1]) {
               let  msg = `${this.$store.state.auth.userDetails.name} added comments in ${this.ticketNumber}`
              this.getsignalRConnection.invoke('SendNotification', [assignedTo[1]], msg, this.ticketId)
            }
          }
        }
        this.getAllComments()
        // this.$root.$emit('loadComments', this.ticketId)
        this.modelObj.comment = ''
        this.showCommentBox = false
      })
    },
    updateComments (index) {
      let model = this.$formatter.cloneVariable(this.listOfComments[index])
      this.$api.execute('put', `ticketcomments/${model._id}`, model).then(response => {
        // this.$root.$emit('loadComments', this.ticketId)
        this.getAllComments()
        this.listOfComments[index].showEdit = false
      })
    },
    deleteComment (comment, index) {
      this.showDialog = true
      this.selectedItem = {
        comment : comment,
        index : index
      }
    },
    confirmDeleteComment() {
      this.$api.execute('delete', `ticketcomments/${this.selectedItem.comment._id}`)
        .then(()=>{
          this.listOfComments.splice(this.selectedItem.index,1)
          this.showDialog = false
        })
    },
  }
}
</script>
<style>
.custom-item  {
  display: block;
}
</style>